<template>
  <div>
    <div class="search-box">
      <div class="search-box-params">
        <TextInput
          style="margin-left: 50px"
          @returnVal="getKeyToValue"
          v-model="params.serviceTypesOfKey"
          :optionConfig="{
            value: 'dictKey',
            label: 'dictValue',
          }"
          :config="{
            type: 'select',
            option: dictList.ServiceTypesOf,
            label: '服务类型',
            modelTextKey: 'serviceTypesOf',
          }"
          labelPosition="left"
        ></TextInput>
        <TextInput
          style="margin-left: 50px"
          @returnVal="getKeyToValue"
          v-model="params.serviceTypeNameKey"
          :optionConfig="{
            value: 'dictKey',
            label: 'dictValue',
          }"
          :config="{
            type: 'select',
            option: dictList.ServiceType,
            label: '服务种类',
            modelTextKey: 'serviceTypeName',
          }"
          labelPosition="left"
        ></TextInput>
        <div style="margin-left: 50px; display: flex; align-items: center">
          <span style="margin-right: 10px">时间</span>
          <el-date-picker
            @change="fastSelect"
            v-model="params.dates"
            type="daterange"
            placeholder="选择日期时间"
            align="right"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="search-box-btns">
        <el-button type="primary" @click="getData">查询</el-button>
        <el-button @click="reset">重置</el-button>
      </div>
    </div>
    <div style="display: flex">
      <CardBox style="flex: 16; margin-right: 10px" title="风险减量服务">
        <div
          @click="toDetail('safeDutyServiceManagement')"
          class="view-more"
          slot="action"
        >
          查看更多 <i class="el-icon-arrow-right"></i>
        </div>
        <div class="chart-box">
          <Chart
            style="height: 100%; width: 100%"
            :option="barChartOption"
          ></Chart>
        </div>
      </CardBox>
      <CardBox style="flex: 8" title="服务项目排名">
        <div class="rank-box">
          <div
            v-for="(item, index) in initData.serviceProjectDataList"
            class="rank-box-item"
          >
            <div class="rank-box-item-left">
              <div
                class="rank-box-item-left-num"
                :style="{
                  color:
                    index < 3
                      ? rankParams.colorList[index].tc
                      : rankParams.colorList[3].tc,
                  backgroundColor:
                    index < 3
                      ? rankParams.colorList[index].bc
                      : rankParams.colorList[3].bc,
                }"
              >
                {{ index + 1 }}
              </div>
              {{ item.name }}
            </div>
            <div style="font-weight: bold">{{ item.count }}次</div>
          </div>
        </div>
      </CardBox>
    </div>
    <SimpleTable
      ref="table"
      :tableProps="tableProps"
      :queryFun="queryFun"
      :extraParams="params"
    >
    </SimpleTable>
  </div>
</template>

<script>
import SimpleTable from "../components/SimpleTable";
import CardBox from "../components/CardBox";
import Chart from "@/components/Chart/Charts";
import { setSerCardChartOption } from "../js/chart.js";
import { getServiceDataBoard, getServiceList } from "@/api/safeDuty.js";
import TextInput from "../components/TextInput.vue";
import { dictionaryBatch } from "@/api/policy";
export default {
  components: { SimpleTable, CardBox, Chart, TextInput },
  mounted() {
    this.init();
  },
  data() {
    return {
      initData: { dataMap: [], serviceProjectDataList: [] },
      dictList: {},
      pickerOptions: {
        shortcuts: [
          //D：天、W：周、M：月、Y：年
          // dateType: "",
          {
            text: "今天",
            onClick: (picker) => {
              this.params.dateType = "D";
            },
          },
          {
            text: "本周",
            onClick: (picker) => {
              this.params.dateType = "W";
            },
          },
          {
            text: "本月",
            onClick: (picker) => {
              this.params.dateType = "M";
            },
          },
          {
            text: "全年",
            onClick: (picker) => {
              this.params.dateType = "Y";
            },
          },
        ],
      },
      dateTypeRange: {
        D: { xname: "时间" },
        W: { xname: "日期" },
        M: { xname: "日期" },
        Y: { xname: "月份" },
      },
      tableProps: {
        isSelection: false,
        isColumnSet: false,
        isHiddenSet: false,
        isSearch: false,
        isRefresh: false,
        isFuzzyQuery: false,
        border: true,
        currentPageKey: "current",
        currentSizeKey: "size",
        height: 300,
        columnProps: [
          {
            prop: "",
            label: "序号",
            width: "55",
            type: "index",
            isShow: true,
          },
          {
            prop: "serviceNo",
            label: "服务编号",
            width: "",
            type: "",
            isShow: true,
          },

          {
            prop: "policyNo",
            label: "保单号",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "insuredName",
            label: "被保险人",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "projectName",
            label: "所属项目",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "serviceTypesOf",
            label: "服务种类",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "serviceProjectName",
            label: "服务项目",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "designateExpert",
            label: "服务专家",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "signInTime",
            label: "服务时间",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "remark",
            label: "备注",
            width: "",
            type: "",
            isShow: true,
          },
        ],
      },
      // 排名榜
      rankParams: {
        // 颜色列表
        colorList: [
          {
            tc: "#F64A2D",
            bc: "rgba(246, 74, 45, 0.1)",
          },
          {
            tc: "rgba(252, 161, 24, 1)",
            bc: "rgba(252, 161, 24, 0.1)",
          },
          {
            tc: "rgba(252, 206, 24, 1)",
            bc: "rgba(252, 206, 24, 0.1)",
          },
          {
            tc: "rgba(51, 51, 51, 1)",
            bc: "rgba(236, 245, 255, 1)",
          },
        ],
      },
      barChartOption: {},
      params: {
        begin: "",
        //D：天、W：周、M：月、Y：年
        dateType: "M",
        end: "",
        dates: [],
        serviceTypeName: "",
        serviceTypeNameKey: "",
        serviceTypesOf: "",
        serviceTypesOfKey: "",
      },
    };
  },
  watch: {
    "params.dateType": {
      handler(n) {
        this.getData();
      },
    },
  },
  methods: {
    setBarChartOption(data) {
      this.barChartOption = setSerCardChartOption(
        data,
        this.dateTypeRange[this.params.dateType].xname
      );
    },
    init() {
      let dictKeys = ["ServiceType", "ServiceTypesOf"];
      dictionaryBatch({
        codes: dictKeys.join(","),
      }).then((res) => {
        if (res && res.data) {
          dictKeys.forEach((key) => {
            this.$set(this.dictList, key, res.data[key] || []);
          });
        }
      });
      getServiceDataBoard(this.params).then((res) => {
        this.initData = res.data;
        this.setBarChartOption(res.data.dataMap);
      });
    },
    reset() {
      this.params = {
        begin: "",
        //D：天、W：周、M：月、Y：年
        dateType: "M",
        end: "",
        dates: [],
        serviceTypeName: "",
        serviceTypeNameKey: "",
        serviceTypesOf: "",
        serviceTypesOfKey: "",
      };
      this.init();
    },
    getKeyToValue(res) {
      if (res.comType == "select") {
        this.params[res.type] = res.data.dictValue;
      }
    },
    getData() {
      this.init();
      this.$refs.table.getData();
    },
    async queryFun(queryParams) {
      console.log(queryParams);
      let res = await getServiceList(queryParams);
      return {
        localData: res.data.records || [{}],
        total: res.data.total,
      };
    },
    fastSelect(e) {
      console.log(e);
    },
    toDetail(pathName) {
      if (!pathName) return;
      this.$router.push({
        name: pathName,
        // params: {
        //   ...this.params,
        // },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .action-container {
  padding: 0 !important;
}
.view-more {
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  color: #333333;
}
.chart-box {
  height: 340px;
  padding: 27px 34px;
}
.rank-box {
  display: flex;
  flex-direction: column;
  padding: 12px 33px 0px 14px;
  &-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    color: #333333;
    margin-bottom: 30px;
    &-left {
      display: flex;
      align-items: center;
      &-num {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        margin-right: 12px;
      }
    }
  }
}
.search-box {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 10px 10px 10px 10px;
  margin-bottom: 10px;
  padding: 10px 0 16px 0;
  align-items: flex-end;
  &-params {
    display: flex;
    align-items: flex-end;
  }
  &-btns {
    margin-right: 12px;
  }
}
</style>
